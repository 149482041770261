import echarts from 'echarts'
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_h_Font,
  line_Font,
  symbolSize_Font,
  lineSize,
} from "../font";

export function getOptions(val, title) {
  return {
    title: {
      text: title,
      textStyle: {
        align: "left", //字体居中
        color: "#fff", //字体颜色
        fontSize: title2_Font(), //字体大小
      },
    },
    grid: {
      left: "5%",
      right: "8%",
      bottom: title2_Font() * 2,
      top: title2_Font() * 3,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      textStyle: {
        lineHeight: line_h_Font(),
        fontSize: x_Font(),
      },
      // formatter: function (params) {
      //   return params[0].name + " : " + params[0].value;
      // },
    },
    legend: {
      bottom: "0%",
      temWidth: w_Font(),
      itemHeight: h_Font()*2,
      textStyle: {
        lineHeight: line_h_Font(),
        color: "#fff",
        fontSize: x_Font(),
      },
      data: ["客房整理", "查房率"]
    },
    dataZoom: [

      {
        type: 'slider',
        yAxisIndex: 0,
        show: false,
        filterMode: 'empty'
      },
      {
        type: 'inside',
        show: false,
        // start: 0,
        // end: val.x.length > 15 ? 35 : 100,
        yAxisIndex: 0,
        filterMode: 'empty'
      }
    ],
    yAxis: [{
        type: "category",
        inverse: true,
        axisLabel: {
          show: true,
          textStyle: {
            fontSize: x_Font(), //字体大小
            color: "#fff",
          },
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
            width: lineSize(),
          },
        },

        data: val.y,
      },

    ],

    xAxis: {
      // show: false,
      type: "value",
      splitLine: {
        lineStyle: {
          type: "dashed",
          width: lineSize(),
        },
      },
      axisLabel: {
        color: "#fff",
        fontSize: x_Font(), //字体大小
      },
    },

    series: [{
        name: "客房整理",
        type: "bar",
        // smooth: true, //是否平滑曲线显示
        // zlevel: 1,
        itemStyle: {
          normal: {
            barBorderRadius: y_Font(),
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: "rgba(61,126,235,1)",
              },
              {
                offset: 1,
                color: "#00C2FC",
              },
            ]),
          },
        },
        barWidth: y_Font(),
        data: val.x,
      },
      {
        name: "查房率",
        type: "bar",
        // smooth: true, //是否平滑曲线显示
        // zlevel: 1,
        itemStyle: {
          normal: {
            barBorderRadius: y_Font(),
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: '#d87a80'
              },
              {
                offset: 1,
                color: '#ffb980'
              }
            ]),
          },
        },
        barWidth: y_Font(),
        data: val.x2,
      },

    ],
  }
}