<template>
  <div class="home">
    <ul class="flexContent flex">
      <li class="leftData flex flex-column">
        <!-- 取电房间数 -->
        <div class="takeNumber bk_b">
          <dv-border-box-10>
            <roomsTop roleType="1" />
          </dv-border-box-10>
        </div>
        <!-- sos报警 -->
        <div class="sosAlarm bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <feifang roleType="1" />
            </div>
          </dv-border-box-10>
        </div>

        <div class="sosAlarm bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <workSpeed roleType="1" />
            </div>
          </dv-border-box-10>
        </div>
      </li>
      <li class="middlePage flex flex-column flex-1">
        <div class="middleContent middle_1">
          <middleInfo  />
        </div>
        <div class="middleContent middle_2">
            <dv-border-box-13>
              <div class="demo listPd">
              <searchRoom roleType="1" />
              </div>
            </dv-border-box-13>
        </div>
      </li>
      <li class="rightData flex flex-column">
        <!-- 设备出租率 -->
        <div class="probability bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <placeOrder roleType="1" />
            </div>
          </dv-border-box-10>
        </div>

        <!-- 出租率情况 -->
        <div class="probability bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <checkInTime roleType="1" />
            </div>
          </dv-border-box-10>
        </div>
        <div class="probability bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <checkInType roleType="1" />
            </div>
          </dv-border-box-10>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import roomsTop from "../components/roomsTop2";
import checkInLine from "../components/checkInLine";
import placeOrder from "../components/placeOrder";
import middleInfo from "../components/middleInfo";
import workSpeed from "../components/workSpeed";
import checkInTime from "../components/checkInTime";
import eqAndpms from "../components/eqAndpms";
import checkInType from "../components/checkInType";
import feifang from "../components/feifang";
// import roomClearBar from "../components/roomClearBar";
import searchRoom from "../components/searchRoom";

export default {
  // name: "Home",
  components: {
    roomsTop,
    checkInTime,
    eqAndpms,
    checkInLine,
    placeOrder,
    middleInfo,
    workSpeed,
    checkInType,
    feifang,
    // roomClearBar,
    searchRoom,
  },

  data() {
    return {};
  },
  mounted() {
    // window.addEventListener("resize", () => {
    //   // this.myChart.resize();
    //   // this.lineEcharts.resize();
    // });
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/kanbanPage.scss";
</style>
