import echarts from 'echarts'
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_h_Font,
  line_Font,
  symbolSize_Font,
  lineSize,
} from "../font";

export function getOptions(val, title) {
  return {
    title: {
      text: title,
      textStyle: {
        align: "left", //字体居中
        color: "#fff", //字体颜色
        fontSize: title2_Font(), //字体大小
      },
    },
    grid: {
      left: "5%",
      right: "8%",
      bottom: "5%",
      top: title2_Font() * 3,
      containLabel: true,
    },
    dataZoom: [
      // {
      //     type: 'slider',
      //     xAxisIndex: 0,
      //     filterMode: 'empty'
      // },
      {
        type: 'slider',
        yAxisIndex: 0,
        show: false,

        filterMode: 'empty'
      },
      // {
      //     type: 'inside',
      //     xAxisIndex: 0,
      //     filterMode: 'empty'
      // },
      {
        type: 'inside',
        show: false,
        yAxisIndex: 0,
        filterMode: 'empty'
      }
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      textStyle: {
        lineHeight: h_Font(),
        fontSize: x_Font(),
      },
      formatter: function (params) {
        return params[0].name + " : " + params[0].value;
      },
    },
    xAxis: {
      // show: false,
      type: "value",
      splitLine: {
        lineStyle: {
          type: "dashed",
          width: lineSize(),
        },
      },
      axisLabel: {
        color: "#fff",
        fontSize: x_Font(), //字体大小
      },
    },
    yAxis: [{
        type: "category",
        inverse: true,
        axisLabel: {
          show: true,
          textStyle: {
            fontSize: x_Font(), //字体大小
            color: "#fff",
          },
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
            width: lineSize(),
          },
        },

        data: val.y,
      },

    ],
    series: [{
        name: "值",
        type: "bar",
        zlevel: 1,
        itemStyle: {
          normal: {
            barBorderRadius: y_Font(),
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: "#5ab1ef",
              },
              {
                offset: 1,
                color: "#00ffc4",
              },
            ]),
          },
        },
        barWidth: y_Font(),
        data: val.x,
      },


    ],
  }
}