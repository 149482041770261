<template>
  <div class="demo">
    <div
      id="workSpeed"
      class="chartBar"
      :class="{ echartNoData: !hasFlag }"
    ></div>
    <div class="changeType" @click="changeType">
      <!-- <span :class="{ active: type == 2 }">顺序</span>/<span :class="{ active: type == 1 }">倒叙</span> -->
      <span class="top" :class="{ activetop: type == 1 }"></span
      ><span class="down" :class="{ activedown: type == 2 }"></span>
    </div>
  </div>
</template>
<script>
import { GET_SEVEN_PMS } from "@/api";
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_h_Font,
  line_Font,
  symbolSize_Font,
  lineSize,
} from "./font";
import { getOptions } from "./horizontalBar/barConfig";
import echarts from "echarts";
export default {
  data() {
    return {
      barData: {
        x: [],
        y: [],
      },
      hasFlag: true,
      tab: [
        { name: "顾红", value: 13 },
        { name: "张琳", value: 11 },
        { name: "王晓岭", value: 10 },
        { name: "李淑媛", value: 15 },
        { name: "王娟", value: 18 },
        // { name: "王慧英", value: 16 },
        // { name: "张红霞", value: 13 },
        // { name: "杨英", value: 12 },
        // { name: "吴静", value: 15 },
        // { name: "李凤琴", value: 18 },
      ],
      type: 2,
    };
  },
  mounted() {
    this.workSpeed = this.$echarts.init(document.getElementById("workSpeed"));
    window.addEventListener("resize", () => {
      this.workSpeed.resize();
    });
    this.getSevenPms();
    // this.daylineInter = null;
    // this.daylineInter = setInterval(() => {
    //   this.getSevenPms();
    // }, 3600000);
  },

  beforeDestroy() {
    // clearInterval(this.daylineInter);
  },
  methods: {
    resize() {
      this.workSpeed.resize();
    },
    changeType() {
      //  if (this.type == 1) {
      //   this.type = 2;
      // } else if (this.type == 2){
      //   this.type = 1;
      // } else{
      //    this.type = 2;
      // }
       this.type= this.type==1?2:1
      this.getSevenPms();
    },
    getSevenPms(data = {}) {
      this.barData = {
        x: [],
        y: [],
      };
      let tablist = [];
      let lists = JSON.parse(JSON.stringify(this.tab));
      switch (this.type) {
        case 1:
          tablist = lists.sort((a, b) => a.value - b.value);
          break;
        case 2:
          tablist = lists.sort((a, b) => b.value - a.value);
          break;
        default :
          tablist = lists;
          break;
      }
      tablist.forEach((v) => {
        this.barData.x.push(v.value);
        this.barData.y.push(v.name);
      });
      if (this.hasFlag) {
        this.initLine();
      }
    },
    initLine() {
      this.workSpeed.setOption(
        getOptions(this.barData, "工作效率（分钟）"),
        true
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
  position: relative;
}
.changeType {
  position: absolute;
  color: #fff;
  top: 0.5vh;
  right: 0.2vw;
  width: 1vw;
  height: 2.2vh;
  font-size: 1.1vh;
  cursor: pointer;
  .active{
    color: #409eff;
  }
  span {
    width: 0;
    height: 0;
    border-width: 0 0.8vh 0.8vh;
    border-style: solid;
    border-color: transparent transparent #ccc;
  }
  .top {
    position: absolute;
    bottom: 30%;
    right: 0.5vw;
  }
  .top.activetop {
    border-bottom-color: red !important;
  }
  .down {
    border-bottom: 0;
    border-top-width: 0.8vh;
    border-top-color: #ccc;
    position: absolute;
    top: 30%;
    left: 0.5vw;
  }
  .down.activedown {
    border-top-color: red !important;
  }
}
</style>