<template>
 <div class="demo">
  <dv-border-box-11 :title="hotelName" :titleWidth="350" v-if="!isPhone">
   <div class="chartLineBar flex-1">
    <div class="demo listPd">
     <checkInLine roleType="1" />
    </div>
   </div>
  </dv-border-box-11>
  <dv-border-box-8 dur="6" v-else>
   <div class="MapTitle">
    <div class="titleName">{{hotelName}}</div>
    <dv-decoration-2 style="width:120%;height:5px;     position:absolute; bottom: 0; left: 50%;transform: translateX(-50%);" />
   </div>
   <div class="chartLineBar flex-1">
    <div class="demo listPd">
     <checkInLine roleType="1" />
    </div>
   </div>
  </dv-border-box-8>
  <!-- <div class="infoTitle">
      <div class="TitleText">
        <span>{{name}}</span>
        <div class="leftborder"></div>
        <div class="leftborder2"></div>
        <div class="rightborder"></div>
        <div class="rightborder2"></div>
      </div>
    </div>
    <div class="chartLineBar flex-1">
      <dv-border-box-8 dur="6">
        <div class=" demo listPd">
          <checkInLine roleType="1" />
        </div>
      </dv-border-box-8>
    </div>-->
 </div>
</template>
<script>
import checkInLine from "../components/checkInLine";
import { getHotelName } from "@/api";
import { isPhone, showName } from "@/api/common.js";

export default {
 components: {
  checkInLine,
 },
 data() {
  return {
   isPhone: isPhone(),
   name: "广州番禺大道希尔顿欢朋酒店",
  };
 },
 created() {
  this.getHotelName();
 },
 computed:{
   hotelName(){
     return showName(this.name,'title')
   }
 },
 methods: {
  getHotelName() {
   getHotelName().then((res) => {
    if (res.data.code == "000000" && res.data.data) {
     this.name = res.data.data;
    }
   });
  },
 },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
 color: #fff;
 height: 100%;
 width: 100%;
 position: relative;
}

// .infoTitle {
//   font-size: 3vh;
//   height: 25%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .TitleText {
//     position: relative;
//     height: 5vh;
//     line-height: 5vh;
//     padding: 0 3vh;
//     border-top: 0.28vh solid #5970b8;
//     border-bottom: 0.28vh solid #5970b8;
//     background-color: #111c66;
//     z-index: 999;
//   }
//   .TitleBgc {
//     position: absolute;
//     top: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 150%;
//   }
//   .leftborder {
//     position: absolute;
//     top: 0;
//     left: -4.35vh;
//     display: block;
//     height: 4.4vh;
//     width: 2.5vh;
//     border-width: 2.2vh 2.2vh 2.2vh;
//     border-style: solid;
//     border-color: transparent #111c66 transparent transparent;
//     z-index: 3;
//   }
//   .leftborder2 {
//     position: absolute;
//     top: -0.3vh;
//     left: -5vh;
//     display: block;
//     height: 5vh;
//     width: 2.5vh;
//     border-width: 2.5vh 2.5vh 2.5vh;
//     border-style: solid;
//     border-color: transparent #5970b8 transparent transparent;
//   }
//   .rightborder2 {
//     position: absolute;
//     top: -0.3vh;
//     right: -2.5vh;
//     display: block;
//     height: 5vh;
//     width: 2.5vh;
//     border-width: 2.5vh 0 2.5vh 2.5vh;
//     border-style: solid;
//     border-color: transparent transparent transparent #5970b8;
//   }
//   .rightborder {
//     position: absolute;
//     top: 0;
//     right: -2.46vh;
//     display: block;
//     height: 4.4vh;
//     width: 2.5vh;
//     border-width: 2.2vh 0 2.2vh 2.2vh;
//     border-style: solid;
//     border-color: transparent transparent transparent #111c66;
//     z-index: 3;
//   }
// }
.MapTitle {
 // width: vw;
 width: 30vh;
 padding-top: 1vh;
 height: 6.5vh;
 position: absolute;
 top: 17px;
 left: 50%;
 transform: translateX(-50%);
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 .titleName {
  padding: 0 1vw;
  white-space: nowrap;
  font-weight: 600;
  font-size: 3vh;
  //  position: absolute;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  color: #fff;
 }
}
.chartLineBar {
 width: 100%;
 // height: 70%;
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 top: 80px;
}
</style>