var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "demo" },
    [
      !_vm.isPhone
        ? _c(
            "dv-border-box-11",
            { attrs: { title: _vm.hotelName, titleWidth: 350 } },
            [
              _c("div", { staticClass: "chartLineBar flex-1" }, [
                _c(
                  "div",
                  { staticClass: "demo listPd" },
                  [_c("checkInLine", { attrs: { roleType: "1" } })],
                  1
                ),
              ]),
            ]
          )
        : _c("dv-border-box-8", { attrs: { dur: "6" } }, [
            _c(
              "div",
              { staticClass: "MapTitle" },
              [
                _c("div", { staticClass: "titleName" }, [
                  _vm._v(_vm._s(_vm.hotelName)),
                ]),
                _c("dv-decoration-2", {
                  staticStyle: {
                    width: "120%",
                    height: "5px",
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    transform: "translateX(-50%)",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "chartLineBar flex-1" }, [
              _c(
                "div",
                { staticClass: "demo listPd" },
                [_c("checkInLine", { attrs: { roleType: "1" } })],
                1
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }