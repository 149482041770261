<template>
  <div class="demo">
    <div id="searchRoom"  class="chartBar" :class="{echartNoData:!hasFlag}"></div>
  </div>
</template>
<script>
import { GET_SEVEN_PMS } from "@/api";
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_h_Font,
  line_Font,
  symbolSize_Font,
  lineSize,
} from "./font";
import { getOptions } from "./horizontalBar/barConfig2";
import { getDay } from "@/api/common.js";

import echarts from "echarts";
export default {
  data() {
    return {
      barData: {
        x: [
          21,
          23,
          18,
          29,
          28,
          21,
          19,
          // 24,20,26,25
        ],
        x2: [
          28,
          21,
          26,
          24,
          25,
          18,
          26,
          // 24,20,26,25
        ],
        y: [],
      },
      hasFlag: true,
    };
  },
  mounted() {
    this.searchRoom = this.$echarts.init(document.getElementById("searchRoom"));
    window.addEventListener("resize", () => {
      this.searchRoom.resize();
    });
    this.getSevenPms();
    // this.daylineInter = null;
    // this.daylineInter = setInterval(() => {
    //   this.getSevenPms();
    // }, 3600000);
  },
  created() {
    this.getTime();
  },
  beforeDestroy() {
    clearInterval(this.daylineInter);
  },
  methods: {
    getTime() {
      this.barData.y = [];
      for (let i = -7; i < 0; i++) {
        let t = getDay(i);
        t = t.substring(5, t.length);
        this.barData.y.push(t);
      }
    },
    resize() {
      this.searchRoom.resize();
    },

    // 获取七日内的pms 出租率情况
    async getSevenPms(data = {}) {
      try {
        // this.hasFlag = true;
        // let res = await GET_SEVEN_PMS(data);
        // ["dayRented", "dayPMS", "day"].forEach((item) => {
        //   this.$set(this.sevenPms, item, []);
        // });
        // console.log(res);
        // if (!res.data.data || res.data.data.length == 0) {
        //   // this.hasFlag = false;
        // } else {
        //   res.data.data.forEach((item) => {
        //     this.sevenPms.dayRented.unshift(item.deviceRentedCount);
        //     // this.sevenPms.dayPMS.unshift(item.occupancyRate);
        //     this.sevenPms.day.unshift(item.createTime);
        //   });
        // }
      } catch (error) {}

      if (this.hasFlag) {
        this.initLine();
      }

      // this.initLine();
      // this.sevenPms = res.data.data
    },
    initLine() {
      this.searchRoom.setOption(getOptions(this.barData, "工作量"));
    },
  },
};
</script>·
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
}
</style>